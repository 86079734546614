import { useQuery, useReactiveVar } from '@apollo/client'
import { Product } from '@commerce/schema'
import {
  getCurrencyCountryCode,
  getCustomerQuery,
  getLanguage,
  getProductQuery,
  normalizeProduct,
} from '@commerce/utils'

import {
  customerAccessTokenVar,
  storefrontCustomerAccessTokenVar,
} from '@commerce/client'
import { Cart } from '@commerce/types'
import Price from '@components/common/Price/Price'
import cn from 'clsx'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useState } from 'react'
import PrepCoatUpsellCards from './PrepCoatUpsellCards'
import UpsellButton from './UpsellButton'
import UpsellItemLoader from './UpsellItemLoader'

const placeholderImg = '/product-img-placeholder.svg'

interface Props {
  handle: string
  index: number
  handleUpsellSelection?: (itemType: string) => void
  cart?: Cart.Cart
}

const UpsellItem = ({ handle, index, cart, handleUpsellSelection }: Props) => {
  const { locale } = useRouter()
  const [addingItemIds, setAddingItemIds] = useState<string[]>([])
  const customerAccessToken = useReactiveVar(customerAccessTokenVar)
  const storefrontCustomerAccessToken = useReactiveVar(
    storefrontCustomerAccessTokenVar
  )

  const { data: customerData, loading: customerLoading } = useQuery(
    getCustomerQuery,
    {
      context: { locale, customerAccessToken, useCustomerApi: true },
      skip: !(customerAccessToken && customerAccessToken.length > 0),
    }
  )

  const companyLocationId =
    customerData?.customer?.companyContacts?.edges?.[0]?.node?.company
      ?.locations?.edges?.[0]?.node?.id

  const { data, loading } = useQuery(getProductQuery, {
    variables: {
      slug: handle,
      country: getCurrencyCountryCode(locale),
      language: getLanguage(locale),
      buyer:
        companyLocationId && storefrontCustomerAccessToken
          ? {
              customerAccessToken: storefrontCustomerAccessToken,
              companyLocationId,
            }
          : undefined,
    },
    context: { locale },
  })

  const product = data?.productByHandle
    ? normalizeProduct(data.productByHandle as Product)
    : null

  return (
    <>
      {loading && !product && (
        <UpsellItemLoader title={`${handle}-${locale}}`} />
      )}

      {product?.handle === 'lime-wash-prep-coat' ? (
        <PrepCoatUpsellCards
          product={product}
          handleUpsellSelection={handleUpsellSelection}
          addingItemIds={addingItemIds}
          setAddingItemIds={setAddingItemIds}
          cart={cart}
        />
      ) : (
        <li
          className={cn('border-x border-ui-grey-25 bg-white', {
            'border-y': index === 0,
            'border-b': index !== 0,
            'pointer-events-none opacity-50': addingItemIds.includes(
              String(product?.variants?.[0]?.id)
            ),
          })}
        >
          <div className="flex items-center gap-3 p-3">
            <div>
              <Image
                src={product?.images?.[0]?.url || placeholderImg}
                width={64}
                height={64}
                alt={product?.images?.[0]?.altText || 'upsell item'}
              />
            </div>
            <div className="basis-36">
              <div className="body-2-bold">{product?.name}</div>
              <Price
                price={product?.price?.value || 0}
                currencyCode={product?.price.currencyCode}
                prefix={false}
                className="body-2"
              />
            </div>
            <UpsellButton
              variantId={String(product?.variants?.[0]?.id)}
              handleUpsellSelection={handleUpsellSelection}
              itemType={'brush'}
              setAddingItemIds={setAddingItemIds}
            />
          </div>
        </li>
      )}
    </>
  )
}

export default UpsellItem
