const Klarna = ({ ...props }) => {
  return (
    <svg
      width="38"
      height="24"
      viewBox="0 0 38 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity="0.07"
        d="M35 0H3C1.3 0 0 1.3 0 3V21C0 22.7 1.4 24 3 24H35C36.7 24 38 22.7 38 21V3C38 1.3 36.6 0 35 0Z"
        fill="black"
      />
      <path
        d="M35 1C36.1 1 37 1.9 37 3V21C37 22.1 36.1 23 35 23H3C1.9 23 1 22.1 1 21V3C1 1.9 1.9 1 3 1H35Z"
        fill="#FFB3C7"
      />
      <path
        d="M29.8277 10.5726C29.4926 10.3374 29.1016 10.2008 28.6967 10.1774C28.2918 10.1541 27.8884 10.2449 27.5299 10.4402C27.1714 10.6354 26.8714 10.9276 26.6621 11.2854C26.4529 11.6432 26.3423 12.0531 26.3423 12.4708C26.3423 12.8885 26.4529 13.2983 26.6621 13.6561C26.8714 14.0139 27.1714 14.3062 27.5299 14.5014C27.8884 14.6966 28.2918 14.7875 28.6967 14.7641C29.1016 14.7408 29.4926 14.6042 29.8277 14.3689V14.6471H31.093V10.2942H29.8277V10.5726ZM28.677 13.5907C28.4645 13.5791 28.2599 13.5035 28.0886 13.3734C27.9173 13.2432 27.7868 13.0641 27.7132 12.8584C27.6396 12.6526 27.6262 12.4291 27.6747 12.2156C27.7231 12.0021 27.8313 11.8079 27.9857 11.657C28.1402 11.5061 28.3342 11.4052 28.5438 11.3667C28.7533 11.3282 28.9693 11.3539 29.1648 11.4405C29.3604 11.5271 29.527 11.6708 29.6441 11.854C29.7612 12.0371 29.8236 12.2515 29.8236 12.4708C29.8155 12.7759 29.6902 13.0652 29.4753 13.2751C29.2603 13.4851 28.9732 13.5986 28.677 13.5907Z"
        fill="black"
      />
      <path
        d="M12.4769 8.35449H11.1528V14.6473H12.4769V8.35449Z"
        fill="black"
      />
      <path
        d="M24.0954 10.1766C23.8467 10.1614 23.5981 10.2088 23.371 10.3147C23.144 10.4205 22.9454 10.5816 22.7922 10.7841V10.2939H21.5322V14.6467H22.8074V12.3591C22.7976 12.2277 22.8154 12.0957 22.8595 11.972C22.9037 11.8483 22.9732 11.7358 23.0633 11.6423C23.1534 11.5487 23.262 11.4763 23.3818 11.4298C23.5015 11.3833 23.6295 11.364 23.7572 11.373C24.3133 11.373 24.6329 11.7152 24.6329 12.3501V14.6467H25.8967V11.8785C25.8967 10.8655 25.1148 10.1766 24.0954 10.1766Z"
        fill="black"
      />
      <path
        d="M16.4801 10.5726C16.145 10.3374 15.7539 10.2008 15.3491 10.1774C14.9442 10.1541 14.5408 10.2449 14.1823 10.4402C13.8238 10.6354 13.5237 10.9276 13.3145 11.2854C13.1052 11.6432 12.9946 12.0531 12.9946 12.4708C12.9946 12.8885 13.1052 13.2983 13.3145 13.6561C13.5237 14.0139 13.8238 14.3062 14.1823 14.5014C14.5408 14.6966 14.9442 14.7875 15.3491 14.7641C15.7539 14.7408 16.145 14.6042 16.4801 14.3689V14.6471H17.7453V10.2942H16.4801V10.5726ZM15.3294 13.5907C15.1168 13.5791 14.9122 13.5036 14.7408 13.3734C14.5695 13.2433 14.439 13.0642 14.3654 12.8585C14.2918 12.6527 14.2784 12.4292 14.3268 12.2157C14.3752 12.0022 14.4834 11.8079 14.6378 11.657C14.7923 11.5061 14.9863 11.4052 15.1959 11.3667C15.4055 11.3282 15.6214 11.3539 15.817 11.4404C16.0125 11.527 16.1792 11.6708 16.2963 11.8539C16.4134 12.0371 16.4758 12.2515 16.4758 12.4708C16.4677 12.7759 16.3424 13.0651 16.1275 13.2751C15.9125 13.4851 15.6255 13.5986 15.3293 13.5907H15.3294Z"
        fill="black"
      />
      <path
        d="M19.7026 10.8612V10.2943H18.4072V14.6472H19.7054V12.6151C19.7054 11.9294 20.4269 11.5608 20.9276 11.5608C20.9326 11.5608 20.9375 11.5613 20.9426 11.5613V10.2945C20.708 10.288 20.4749 10.3356 20.2604 10.4336C20.0459 10.5316 19.8554 10.6777 19.7026 10.8612Z"
        fill="black"
      />
      <path
        d="M32.4362 13.1035C32.2791 13.1035 32.1256 13.1515 31.995 13.2414C31.8644 13.3313 31.7626 13.459 31.7025 13.6085C31.6424 13.758 31.6267 13.9225 31.6574 14.0811C31.688 14.2398 31.7636 14.3856 31.8747 14.5C31.9858 14.6144 32.1273 14.6923 32.2813 14.7238C32.4353 14.7554 32.595 14.7392 32.7401 14.6773C32.8852 14.6154 33.0093 14.5105 33.0965 14.376C33.1838 14.2415 33.2303 14.0833 33.2303 13.9215C33.2304 13.8141 33.2098 13.7077 33.1699 13.6085C33.13 13.5092 33.0715 13.419 32.9978 13.3431C32.924 13.2671 32.8365 13.2069 32.7401 13.1658C32.6437 13.1246 32.5405 13.1035 32.4362 13.1035Z"
        fill="black"
      />
      <path
        d="M10.2404 8.35254H8.86819C8.87032 8.91939 8.74301 9.47882 8.49654 9.98565C8.25007 10.4925 7.89136 10.9325 7.44936 11.2702L6.90576 11.6896L9.01223 14.6486H10.744L8.80591 11.9259C9.263 11.4579 9.6252 10.901 9.87147 10.2876C10.1177 9.67413 10.2431 9.01637 10.2404 8.35254Z"
        fill="black"
      />
      <path
        d="M6.73794 8.35254H5.36328V14.6483H6.73794V8.35254Z"
        fill="black"
      />
    </svg>
  )
}

export default Klarna
