import { useMutation } from '@apollo/client'
import cartLinesAddMutation, {
  cartLinesAddVars,
} from '@commerce/utils/mutations/cart-lines-add'
import { Check, Plus } from '@components/icons'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useState } from 'react'

interface UpsellButtonProps {
  variantId: string
  handleUpsellSelection?: (itemType: string) => void
  itemType: string
  setAddingItemIds: React.Dispatch<React.SetStateAction<string[]>>
}

const UpsellButton: React.FC<UpsellButtonProps> = ({
  variantId,
  handleUpsellSelection,
  itemType,
  setAddingItemIds,
}) => {
  const { t } = useTranslation('cart')
  const { locale } = useRouter()
  const [addingItem, setAddingItem] = useState(false)
  const [itemAdded, setItemAdded] = useState(false)

  const [addItem] = useMutation(cartLinesAddMutation, {
    onCompleted() {
      setAddingItem(false)
      setItemAdded(true)
      setAddingItemIds((prevState) =>
        prevState.filter((id) => id !== variantId)
      )
      handleUpsellSelection && handleUpsellSelection(itemType)
    },
    onError() {
      setAddingItem(false)
      setAddingItemIds((prevState) =>
        prevState.filter((id) => id !== variantId)
      )
    },
  })

  const addToCart = async () => {
    setAddingItem(true)
    setAddingItemIds((prevState: string[]) => [...prevState, variantId])

    addItem({
      variables: cartLinesAddVars({
        locale,
        variantId,
        quantity: 1,
      }),
      context: { locale },
    })
  }

  return (
    <button
      className={`body-2 flex min-w-16 items-center justify-center gap-1 border border-ui-darkest-grey px-1 ${
        addingItem ? 'opacity-20' : ''
      }`}
      disabled={addingItem || itemAdded}
      onClick={addToCart}
    >
      {itemAdded ? (
        <>
          {`${t('added')}`}
          <Check width={14} height={14} viewBox="0 0 24 24" />
        </>
      ) : (
        <>
          {`${t('add')}`}
          <Plus width={14} height={14} viewBox="0 0 24 24" />
        </>
      )}
    </button>
  )
}

export default UpsellButton
