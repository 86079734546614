import commerceConfig from '@commerce/config'
import processLocale, { PaymentMethods } from '@lib/locale'
import Amex from './Amex'
import ApplePay from './ApplePay'
import Bancontact from './Bancontact'
import BankTransfer from './BankTransfer'
import GooglePay from './GooglePay'
import IDeal from './IDeal'
import Klarna from './Klarna'
import Maestro from './Maestro'
import Mastercard from './Mastercard'
import ShopPay from './ShopPay'
import Sofort from './Sofort'
import Visa from './Visa'

const PaymentOptions = ({ locale }: any) => {
  const {
    config: { context },
  } = commerceConfig
  const currentLocale = processLocale(locale)
  const currentContext = context?.[currentLocale]
  const paymentMethods = currentContext?.paymentMethods
  if (!paymentMethods) return <></>
  return (
    <div className="flex w-full flex-wrap items-center justify-center gap-1">
      {paymentMethods &&
        paymentMethods.map((method: PaymentMethods, index: number) => {
          return (
            <div key={index}>
              {method === 'MASTERCARD' && <Mastercard />}
              {method === 'VISA' && <Visa />}
              {method === 'AMEX' && <Amex />}
              {method === 'MAESTRO' && <Maestro />}
              {method === 'BANCONTACT' && <Bancontact />}
              {method === 'IDEAL' && <IDeal />}
              {method === 'SOFORT' && <Sofort />}
              {method === 'SHOPPAY' && <ShopPay />}
              {method === 'APPLEPAY' && <ApplePay />}
              {method === 'GOOGLEPAY' && <GooglePay />}
              {method === 'KLARNA' && <Klarna />}
              {method === 'BANKTRANSFER' && <BankTransfer />}
            </div>
          )
        })}
    </div>
  )
}

export default PaymentOptions
