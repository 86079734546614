import { Cart } from '@commerce/types'
import type { Product } from '@commerce/types/product'
import Price from '@components/common/Price/Price'
import { useUI } from '@components/ui'
import { checkUpsellProductExistsByVariant } from '@lib/cart'
import cn from 'clsx'
import Image from 'next/image'
import UpsellButton from './UpsellButton'

const placeholderImg = '/product-img-placeholder.svg'

interface PrepCoatUpsellCardsProps {
  product: Product
  handleUpsellSelection?: (itemType: string) => void
  addingItemIds: string[]
  setAddingItemIds: React.Dispatch<React.SetStateAction<string[]>>
  cart?: Cart.Cart
}

const PrepCoatUpsellCards = ({
  product,
  handleUpsellSelection,
  addingItemIds,
  setAddingItemIds,
  cart,
}: PrepCoatUpsellCardsProps) => {
  const { selectedLiters } = useUI()
  let iterations

  if (selectedLiters === null) {
    iterations = product?.variants?.length // Default to full length
  } else if (selectedLiters < 4) {
    iterations = 1
  } else if (selectedLiters >= 4 && selectedLiters < 10) {
    iterations = 2
  }

  const variantsSubset = product?.variants?.slice(0, iterations)

  return (
    <>
      {variantsSubset.map((item, index) => {
        // check if variant id already exists in the cart
        const variantId = item.id
        const isVaritantExists =
          cart && checkUpsellProductExistsByVariant(cart, variantId)

        if (isVaritantExists && isVaritantExists.length > 0) return null

        return (
          <li
            className={cn('border-x border-ui-grey-25 bg-white', {
              'border-y': index === 0,
              'border-b': index !== 0,
              'pointer-events-none opacity-50': addingItemIds.includes(
                String(item?.id)
              ),
            })}
            key={index}
          >
            <div className="flex items-center gap-3 p-3">
              <div className="relative aspect-square w-16">
                <Image
                  src={item?.image?.src || placeholderImg}
                  fill
                  alt={item?.image?.altText || 'upsell item'}
                />
              </div>
              <div className="basis-36">
                <div className="body-2-bold">{item?.name}</div>
                <Price
                  price={item?.price}
                  currencyCode={product?.price.currencyCode}
                  prefix={false}
                  className="body-2"
                />
              </div>
              <UpsellButton
                variantId={String(item?.id)}
                handleUpsellSelection={handleUpsellSelection}
                itemType="prepCoat"
                setAddingItemIds={setAddingItemIds}
              />
            </div>
          </li>
        )
      })}
    </>
  )
}

export default PrepCoatUpsellCards
