import { useMutation, useQuery } from '@apollo/client'
import {
  customerAccessTokenVar,
  storefrontCustomerAccessTokenVar,
} from '@commerce/client'
import commerceConfig from '@commerce/config'
import {
  cartCreateMutation,
  getCartId,
  getCartQuery,
  getCurrencyCountryCode,
  getCustomerToken,
  getLanguage,
  setCartId,
  setCheckoutUrl,
} from '@commerce/utils'
import { getStorefrontCustomerToken } from '@commerce/utils/storefront-customer-token'
import RegionSwitcher from '@components/RegionSwitcher'
import { useArkadeList } from '@components/arkade/list/context'
import CartSidebarView from '@components/cart/CartSidebarView'
import { Footer, HotJarFeedbackButton, Navbar } from '@components/common'
import { MenuSidebarView } from '@components/common/UserNav'
import Calculator from '@components/paintCalculator/Calculator'
import GardenPotModal from '@components/product/GardenPotModal'
import PaintSelectionModal from '@components/product/PaintSelectionModal'
import SurfaceDetailsModal from '@components/product/SurfaceDetailsModal'
import SurfaceSelectionModal from '@components/product/SurfaceSelectionModal'
import MobileProfileSidebar from '@components/profile/MobileProfileSidebar'
import { Button, LoadingDots, Sidebar } from '@components/ui'
import { useUI } from '@components/ui/context'
import getTempListId from '@lib/arkade/list/utils/getTempListId'
import getUserTempListId from '@lib/arkade/list/utils/getUserTempListId'
import { getUnAuthEmailFromStorage } from '@lib/arkade/list/utils/helper'
import { useAcceptCookies } from '@lib/hooks/useAcceptCookies'
import useCustomer from '@lib/hooks/useCustomer'
import processLocale from '@lib/locale'
import { PrismicDocument } from '@prismicio/types'
import { SpeedInsights } from '@vercel/speed-insights/next'
import cn from 'clsx'
import { AnimatePresence } from 'framer-motion'
import { useTranslation } from 'next-i18next'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { ReactNode, useEffect } from 'react'
import AlertBar from '../AlertBar'
import s from './Layout.module.css'

const Loading = () => (
  <div className="flex h-14 w-full items-center justify-center p-3 text-center lg:h-20">
    <LoadingDots />
  </div>
)

const dynamicProps = {
  loading: Loading,
}

const CountrySelectorView = dynamic(
  () => import('@components/common/CountrySelector'),
  {
    ...dynamicProps,
  }
)

const UpsellView = dynamic(() => import('@components/common/UpsellSideBar'), {
  ...dynamicProps,
})

const SearchBarView = dynamic(() => import('@components/common/Searchbar'), {
  ...dynamicProps,
  ssr: false,
})

const FeatureBar = dynamic(() => import('@components/common/FeatureBar'), {
  ...dynamicProps,
})

const Modal = dynamic(() => import('@components/ui/Modal'), {
  ...dynamicProps,
  ssr: false,
})
const WishListSideBar = dynamic(
  () => import('@components/arkade/list/WishListSideBar'),
  { ...dynamicProps, ssr: false }
)
const ShareWishListSideBar = dynamic(
  () => import('@components/arkade/list/ShareWishListSideBar'),
  { ...dynamicProps, ssr: false }
)
const ShareLinkSideBar = dynamic(
  () => import('@components/common/ShareLinkSideBar'),
  { ...dynamicProps, ssr: false }
)
const CreateNewListSideBar = dynamic(
  () => import('@components/arkade/list/CreateNewListSideBar'),
  { ...dynamicProps, ssr: false }
)
const EditListSideBar = dynamic(
  () => import('@components/arkade/list/EditListSideBar'),
  { ...dynamicProps, ssr: false }
)
const ListActionSideBar = dynamic(
  () => import('@components/arkade/list/ListActionSideBar'),
  { ...dynamicProps, ssr: false }
)
interface Props {
  children: ReactNode
  pageProps: {
    mainNav?: any
    footer?: any
    fit?: boolean
    colourProductContent?: PrismicDocument
  }
}

const ModalView: React.FC<{
  modalView: string
  closeModal(): any
  colourProductContent: PrismicDocument
}> = ({ modalView, closeModal, colourProductContent }) => {
  return (
    <Modal onClose={closeModal}>
      {modalView === 'COUNTRY_SELECTOR_VIEW' && (
        <CountrySelectorView onClose={closeModal} />
      )}
      {modalView === 'SURFACE_DETAIL_MODAL_VIEW' && (
        <SurfaceDetailsModal
          prismicContent={colourProductContent}
          onClose={closeModal}
        />
      )}
      {modalView === 'PAINT_CALCULATOR_MODAL_VIEW' && (
        <Calculator onClose={closeModal} />
      )}
      {modalView === 'SURFACE_SELECTION_MODAL_VIEW' && (
        <SurfaceSelectionModal onClose={closeModal} />
      )}
      {modalView === 'PAINT_SELECTION_MODAL_VIEW' && (
        <PaintSelectionModal
          prismicContent={colourProductContent}
          onClose={closeModal}
        />
      )}
      {modalView === 'GARDEN_POT_MODAL_VIEW' && (
        <GardenPotModal
          prismicContent={colourProductContent}
          onClose={closeModal}
        />
      )}
    </Modal>
  )
}

const ModalUI: React.FC<{ colourProductContent: PrismicDocument }> = ({
  colourProductContent,
}) => {
  const { displayModal, closeModal, modalView } = useUI()
  return displayModal ? (
    <ModalView
      colourProductContent={colourProductContent}
      modalView={modalView}
      closeModal={closeModal}
    />
  ) : null
}

const SidebarView: React.FC<{
  sidebarView: string
  closeSidebar(): any
  menuItems: any
  colourProductContent: PrismicDocument
}> = ({ sidebarView, closeSidebar, menuItems, colourProductContent }) => {
  let slideInFrom = 'slideInFromRight'
  const { t } = useTranslation('common')
  let classNames = 'w-full'
  if (sidebarView === 'MOBILEMENU_VIEW') slideInFrom = 'slideInFromLeft'
  if (sidebarView === 'SEARCH_VIEW') slideInFrom = 'slideInFromTop'
  if (
    sidebarView === 'CART_VIEW' ||
    sidebarView === 'WISH_LIST_SIDEBAR_VIEW' ||
    sidebarView === 'SAVE_WISH_LIST_SIDEBAR_VIEW' ||
    sidebarView === 'SHARE_WISH_LIST_SIDEBAR_VIEW' ||
    sidebarView === 'SHARE_ARTICLE_SIDEBAR_VIEW' ||
    sidebarView === 'UPSELL_VIEW' ||
    sidebarView === 'CREATE_NEW_LIST_SIDEBAR_VIEW' ||
    sidebarView === 'ADD_TO_LIST_SIDEBAR_VIEW' ||
    sidebarView === 'POST_CREATE_NEW_LIST_SIDEBAR_VIEW' ||
    sidebarView === 'CREATE_NEW_LIST_ONLY_SIDEBAR_VIEW' ||
    sidebarView === 'EDIT_WISH_LIST_NAME_SIDEBAR_VIEW' ||
    sidebarView === 'DELETE_WISH_LIST_SIDEBAR_VIEW' ||
    sidebarView === 'MOVE_LIST_ITEM_SIDEBAR_VIEW' ||
    sidebarView === 'DUPLICATE_LIST_ITEM_SIDEBAR_VIEW' ||
    sidebarView === 'POST_MOVE_LIST_ITEM_SIDEBAR_VIEW' ||
    sidebarView === 'POST_DUPLICATE_LIST_ITEM_SIDEBAR_VIEW' ||
    sidebarView === 'LIST_ACTIONS_SIDEBAR_VIEW'
  )
    classNames = 'w-full md:w-92'
  if (
    sidebarView === 'SHARE_WISH_LIST_SIDEBAR_MOBILE_VIEW' ||
    sidebarView === 'SAVE_WISH_LIST_SIDEBAR_MOBILE_VIEW' ||
    sidebarView === 'SHARE_ARTICLE_SIDEBAR_MOBILE_VIEW' ||
    sidebarView === 'COUNTRY_SELECTOR_VIEW' ||
    sidebarView === 'SURFACE_DETAIL_MODAL_VIEW' ||
    sidebarView === 'SURFACE_SELECTION_MODAL_VIEW' ||
    sidebarView === 'MOBILE_PROFILE_SIDEBAR_VIEW' ||
    sidebarView === 'PAINT_SELECTION_MODAL_VIEW' ||
    sidebarView === 'GARDEN_POT_MODAL_VIEW' ||
    sidebarView === 'EDIT_WISH_LIST_NAME_SIDEBAR_MOBILE_VIEW' ||
    sidebarView === 'DELETE_WISH_LIST_SIDEBAR_MOBILE_VIEW' ||
    sidebarView === 'MOVE_LIST_ITEM_SIDEBAR_MOBILE_VIEW' ||
    sidebarView === 'DUPLICATE_LIST_ITEM_SIDEBAR_MOBILE_VIEW' ||
    sidebarView === 'LIST_ACTIONS_MOBILE_SIDEBAR_VIEW' ||
    sidebarView === 'LIST_ITEM_ACTIONS_MOBILE_SIDEBAR_VIEW'
  )
    slideInFrom = 'slideInFromBottom'
  return (
    <Sidebar
      onClose={closeSidebar}
      slideInFromClassName={slideInFrom}
      classNames={classNames}
    >
      {sidebarView === 'CART_VIEW' && (
        <CartSidebarView onClose={closeSidebar} />
      )}
      {sidebarView === 'MOBILEMENU_VIEW' && (
        <MenuSidebarView menuItems={menuItems} onClose={closeSidebar} />
      )}
      {sidebarView === 'COUNTRY_SELECTOR_VIEW' && (
        <CountrySelectorView onClose={closeSidebar} />
      )}
      {sidebarView === 'SURFACE_DETAIL_MODAL_VIEW' && (
        <SurfaceDetailsModal
          prismicContent={colourProductContent}
          onClose={closeSidebar}
        />
      )}
      {sidebarView === 'SURFACE_SELECTION_MODAL_VIEW' && (
        <SurfaceSelectionModal onClose={closeSidebar} />
      )}
      {sidebarView === 'SEARCH_VIEW' && (
        <SearchBarView onClose={closeSidebar} />
      )}
      {sidebarView === 'MOBILE_PROFILE_SIDEBAR_VIEW' && (
        <MobileProfileSidebar
          onClose={closeSidebar}
          title={`${t('your-profile')}`}
        />
      )}
      {sidebarView === 'PAINT_SELECTION_MODAL_VIEW' && (
        <PaintSelectionModal
          prismicContent={colourProductContent}
          onClose={closeSidebar}
        />
      )}
      {sidebarView === 'GARDEN_POT_MODAL_VIEW' && (
        <GardenPotModal
          prismicContent={colourProductContent}
          onClose={closeSidebar}
        />
      )}
      {sidebarView === 'WISH_LIST_SIDEBAR_VIEW' && (
        <WishListSideBar onClose={closeSidebar} />
      )}
      {sidebarView === 'SAVE_WISH_LIST_SIDEBAR_VIEW' && (
        <WishListSideBar isSavingProcess onClose={closeSidebar} />
      )}
      {sidebarView === 'SAVE_WISH_LIST_SIDEBAR_MOBILE_VIEW' && (
        <WishListSideBar isSavingProcess onClose={closeSidebar} />
      )}
      {sidebarView === 'ADD_TO_LIST_SIDEBAR_VIEW' && (
        <WishListSideBar isAddingToListProcess onClose={closeSidebar} />
      )}
      {sidebarView === 'SHARE_WISH_LIST_SIDEBAR_VIEW' && (
        <ShareWishListSideBar onClose={closeSidebar} />
      )}
      {sidebarView === 'SHARE_WISH_LIST_SIDEBAR_MOBILE_VIEW' && (
        <ShareWishListSideBar onClose={closeSidebar} />
      )}
      {sidebarView === 'SHARE_ARTICLE_SIDEBAR_VIEW' && (
        <ShareLinkSideBar onClose={closeSidebar} />
      )}
      {sidebarView === 'SHARE_ARTICLE_SIDEBAR_MOBILE_VIEW' && (
        <ShareLinkSideBar onClose={closeSidebar} />
      )}
      {sidebarView === 'CREATE_NEW_LIST_SIDEBAR_VIEW' && (
        <CreateNewListSideBar onClose={closeSidebar} />
      )}
      {sidebarView === 'CREATE_NEW_LIST_ONLY_SIDEBAR_VIEW' && (
        <CreateNewListSideBar onClose={closeSidebar} />
      )}
      {sidebarView === 'POST_CREATE_NEW_LIST_SIDEBAR_VIEW' && (
        <CreateNewListSideBar onClose={closeSidebar} />
      )}
      {sidebarView === 'POST_MOVE_LIST_ITEM_SIDEBAR_VIEW' && (
        <CreateNewListSideBar onClose={closeSidebar} />
      )}
      {sidebarView === 'POST_DUPLICATE_LIST_ITEM_SIDEBAR_VIEW' && (
        <CreateNewListSideBar onClose={closeSidebar} />
      )}
      {sidebarView === 'EDIT_WISH_LIST_NAME_SIDEBAR_VIEW' && (
        <EditListSideBar onClose={closeSidebar} action={'editName'} />
      )}
      {sidebarView === 'EDIT_WISH_LIST_NAME_SIDEBAR_MOBILE_VIEW' && (
        <EditListSideBar onClose={closeSidebar} action={'editName'} />
      )}
      {sidebarView === 'DELETE_WISH_LIST_SIDEBAR_VIEW' && (
        <EditListSideBar onClose={closeSidebar} action={'deleteList'} />
      )}
      {sidebarView === 'DELETE_WISH_LIST_SIDEBAR_MOBILE_VIEW' && (
        <EditListSideBar onClose={closeSidebar} action={'deleteList'} />
      )}
      {sidebarView === 'MOVE_LIST_ITEM_SIDEBAR_VIEW' && (
        <EditListSideBar onClose={closeSidebar} action={'moveItem'} />
      )}
      {sidebarView === 'MOVE_LIST_ITEM_SIDEBAR_MOBILE_VIEW' && (
        <EditListSideBar onClose={closeSidebar} action={'moveItem'} />
      )}
      {sidebarView === 'DUPLICATE_LIST_ITEM_SIDEBAR_VIEW' && (
        <EditListSideBar onClose={closeSidebar} action={'duplicateItem'} />
      )}
      {sidebarView === 'DUPLICATE_LIST_ITEM_SIDEBAR_MOBILE_VIEW' && (
        <EditListSideBar onClose={closeSidebar} action={'duplicateItem'} />
      )}
      {sidebarView === 'LIST_ITEM_ACTIONS_MOBILE_SIDEBAR_VIEW' && (
        <ListActionSideBar action={'listItemActions'} onClose={closeSidebar} />
      )}
      {sidebarView === 'LIST_ACTIONS_MOBILE_SIDEBAR_VIEW' && (
        <ListActionSideBar action={'listActions'} onClose={closeSidebar} />
      )}
      {sidebarView === 'LIST_ACTIONS_SIDEBAR_VIEW' && (
        <ListActionSideBar action={'listActions'} onClose={closeSidebar} />
      )}
      {sidebarView === 'UPSELL_VIEW' && <UpsellView onClose={closeSidebar} />}
    </Sidebar>
  )
}

const SidebarUI: React.FC<{
  menuItems: any
  colourProductContent: PrismicDocument
}> = ({ menuItems, colourProductContent }) => {
  const { displaySidebar, closeSidebar, sidebarView } = useUI()

  const handleClose = () => {
    closeSidebar()
  }

  return (
    <AnimatePresence>
      {displaySidebar ? (
        <SidebarView
          colourProductContent={colourProductContent}
          menuItems={menuItems}
          sidebarView={sidebarView}
          closeSidebar={handleClose}
        />
      ) : null}
    </AnimatePresence>
  )
}

const Layout: React.FC<Props> = ({ children, pageProps: { ...pageProps } }) => {
  const { t } = useTranslation('common')
  const { acceptedCookies, onAcceptCookies } = useAcceptCookies()
  const { setCalculatorUnit } = useUI()
  const { locale = 'en-au' } = useRouter()

  // get cartid from cookie
  const cartId = getCartId(locale)

  // get tokens in the cookie and set in the reactive variables
  const customerAccessToken = getCustomerToken(locale)
  customerAccessTokenVar(customerAccessToken)
  const storefrontCustomerAccessToken = getStorefrontCustomerToken(locale)
  storefrontCustomerAccessTokenVar(storefrontCustomerAccessToken)

  const { customer, loading: customerLoading } = useCustomer()
  const {
    setListOwnerEmail,
    setTempListId,
    listOwnerEmail,
    setUserTempListId,
    userTempListId,
  } = useArkadeList()
  const [createCart] = useMutation(cartCreateMutation, {
    context: { locale },
    variables: {
      input: {
        buyerIdentity: {
          countryCode: getCurrencyCountryCode(locale),
        },
      },
      country: getCurrencyCountryCode(locale),
    },
    onCompleted(data) {
      const cart = data?.cartCreate?.cart
      const cartId = cart?.id
      const checkoutUrl = cart?.checkoutUrl
      if (cartId) {
        setCartId(locale, cartId)
        setCheckoutUrl(locale, checkoutUrl)
      }
    },
  })
  const { loading, error, data } = useQuery(getCartQuery, {
    variables: {
      cartId,
      country: getCurrencyCountryCode(locale),
      language: getLanguage(locale),
    },
    context: { locale },
    skip: !cartId,
  })
  useEffect(() => {
    //create new cart if no cart id
    //create new cart if there is error(edge case due to env switching)
    //create new cart if cart is null (cart is completed and deleted)
    if (
      !cartId ||
      (!loading && error) ||
      (cartId && !loading && data && !data?.cart)
    ) {
      createCart()
    }
  }, [cartId, createCart, data, error, loading])

  useEffect(() => {
    if (!customerLoading) {
      const unAuthEmail = getUnAuthEmailFromStorage(locale)
      const listOwnerEmail =
        customer?.emailAddress?.emailAddress || unAuthEmail || null
      setListOwnerEmail(listOwnerEmail)
      //Set user's templist Id
      listOwnerEmail &&
        getUserTempListId(listOwnerEmail, locale).then((res) => {
          setUserTempListId(res)
        })
    }
  }, [
    customer?.emailAddress?.emailAddress,
    customerLoading,
    locale,
    setListOwnerEmail,
    setUserTempListId,
  ])
  useEffect(() => {
    !listOwnerEmail && setUserTempListId(null)
  }, [listOwnerEmail, setUserTempListId])
  //Set local templist Id for not unauthed user
  useEffect(() => {
    getTempListId(locale).then((res) => {
      setTempListId(res)
    })
  }, [locale, setTempListId, listOwnerEmail])

  useEffect(() => {
    const {
      config: { context },
    } = commerceConfig
    const currentLocale = processLocale(locale)
    const currentContext = context?.[currentLocale]
    const calculatorUnit = currentContext?.calculatorUnit
    setCalculatorUnit(calculatorUnit)
  }, [locale, setCalculatorUnit])

  return (
    <div className={cn(s.root)}>
      <HotJarFeedbackButton />
      <RegionSwitcher />
      <AlertBar data={pageProps.mainNav?.data} key={`alert-bar-${locale}`} />
      <Navbar links={[]} navItems={pageProps.mainNav} />
      <main
        className={cn({
          '': pageProps?.fit === false,
          fit: pageProps?.fit !== false,
        })}
      >
        {children}
      </main>
      <Footer footer={pageProps.footer} />
      <ModalUI colourProductContent={pageProps.colourProductContent!} />

      <SidebarUI
        colourProductContent={pageProps.colourProductContent!}
        menuItems={pageProps.mainNav}
      />

      <FeatureBar
        title={`${t('cookies-improve-experience-agree')}`}
        hide={acceptedCookies}
        action={
          <Button className="mx-5" onClick={() => onAcceptCookies()}>
            {t('accept-cookies')}
          </Button>
        }
      />
      <SpeedInsights />
    </div>
  )
}

export default Layout
