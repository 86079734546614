const FeedbackClose = ({ ...props }) => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0 0H18C22.4183 0 26 3.58172 26 8V26H0V0Z" />
      <path
        d="M9.58832 10.1009C9.45374 9.96635 9.23553 9.96635 9.10094 10.1009C8.96635 10.2355 8.96635 10.4537 9.10094 10.5883L12.5126 14L9.10094 17.4117C8.96635 17.5463 8.96635 17.7645 9.10094 17.8991C9.23553 18.0336 9.45374 18.0336 9.58832 17.8991L13 14.4874L16.4117 17.8991C16.5463 18.0336 16.7645 18.0336 16.8991 17.8991C17.0336 17.7645 17.0336 17.5463 16.8991 17.4117L13.4874 14L16.8991 10.5883C17.0336 10.4537 17.0336 10.2355 16.8991 10.1009C16.7645 9.96635 16.5463 9.96635 16.4117 10.1009L13 13.5126L9.58832 10.1009Z"
        fill="#646464"
      />
    </svg>
  )
}

export default FeedbackClose
