import { FeedbackClose } from '@components/icons'
import { FC, useEffect, useState } from 'react'

interface Props {}

const HotJarFeedbackButton: FC<Props> = () => {
  const [showFeedbackButton, setShowFeedbackButton] = useState(true)
  const [showWidget, setShowWidget] = useState(false)

  useEffect(() => {
    const widgetContainer = document.getElementById('_hj_feedback_container')
    const widgetCloseButton = widgetContainer?.querySelector(
      '[aria-label="Close"]'
    )
    const handleClose = () => {
      setShowFeedbackButton(true)
      setShowWidget(false)
    }
    if (widgetCloseButton) {
      widgetCloseButton.addEventListener('click', handleClose)
    }
    return () => {
      if (widgetCloseButton) {
        widgetCloseButton.addEventListener('click', handleClose)
      }
    }
  }, [showFeedbackButton])

  const handleButtonClick = () => {
    const defaultHotJarFeedbackButton = document
      .getElementById('_hj_feedback_container')
      ?.querySelector(
        '[class*="MinimizedWidgetMiddle__label"]'
      ) as HTMLElement | null
    if (defaultHotJarFeedbackButton) {
      defaultHotJarFeedbackButton.click()
      setShowFeedbackButton(false)
      setShowWidget(true)
    }
  }

  return (
    <>
      {showFeedbackButton && !showWidget && (
        <div className="customFeedbackButton">
          <div className="flex rotate-180 transform flex-col ">
            <button
              className="body-2 rounded-tl-lg bg-brand-white px-[3px] py-3 text-dark-grey opacity-80 transition-all duration-300 hover:opacity-100"
              onClick={handleButtonClick}
              onTouchStart={handleButtonClick}
            >
              <div style={{ writingMode: 'vertical-rl' }}> Feedback</div>
            </button>
            <button
              className="h-26 flex items-center justify-center rounded-bl-lg bg-brand-white opacity-80 transition-all duration-300 hover:opacity-100"
              onClick={() => setShowFeedbackButton(false)}
            >
              <FeedbackClose />
            </button>
          </div>
        </div>
      )}
      <style jsx>
        {`
          .customFeedbackButton {
            position: fixed;
            z-index: 9999;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        `}
      </style>
    </>
  )
}

export default HotJarFeedbackButton
