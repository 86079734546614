import { Cart } from '@commerce/types'
import { Button } from '@components/ui'
import { useTranslation } from 'next-i18next'
interface Props {
  cart: Cart.Cart
  checkoutUrl: string | undefined
}
const CheckoutButton = ({ cart, checkoutUrl }: Props) => {
  const { t } = useTranslation('common')
  // const { hits, sendEvent } = useHits()
  return (
    <div>
      <Button
        onClick={() => {
          // sendEvent('conversion', hits, 'Checkout Viewed', {
          //   eventSubtype: 'purchase',
          //   cart: cart,
          // })
        }}
        href={checkoutUrl}
        Component="a"
        width="100%"
        className="rounded-sm text-label font-normal"
      >
        {t('checkout')}
      </Button>
    </div>
  )
}
export default CheckoutButton
