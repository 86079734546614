import FancyArrowRight from '@components/icons/FancyArrowRight'
import { Button, useUI } from '@components/ui'
import cn from 'clsx'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import React, { FC, Fragment, useState } from 'react'

interface Props {}

const SubscribeForm: FC<Props> = () => {
  const { locale } = useRouter()
  const [email, setEmail] = useState('')
  const [error, setError] = useState(false)
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const { formFocused, formUnfocused } = useUI()
  const { t } = useTranslation('common')

  const handleOnChange = (e: React.SyntheticEvent<EventTarget>) => {
    const value = (e.target as HTMLInputElement).value
    setEmail(value)
  }

  const handleSubscription = async () => {
    setLoading(true)
    setError(false)
    setMessage('')
    if (
      email &&
      email.match(
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      )
    ) {
      try {
        const response = await fetch('/api/customer/subscribe', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, locale, source: 'footer' }),
        })
        const result = await response.json()
        if (result?.acceptsMarketing) {
          setMessage(`${t('successfully-subscribed')}`)
          setLoading(false)
          setSuccess(true)
          //newsletterSubscriptionComplete()
        } else {
          setError(true)
          setMessage(`${t('failed-subscribed')}`)
          setLoading(false)
        }
      } catch (err) {
        setError(true)
        setMessage(`${t('failed-subscribed')}`)
        setLoading(false)
      }
    } else {
      setError(true)
      setMessage(`${t('enter-valid-email')}`)
      setLoading(false)
    }
  }

  return (
    <Fragment>
      <div className="flex items-stretch">
        <input
          className="body-2 mr-3 w-full appearance-none border-b border-ui-grey-25 bg-transparent text-brand-dark-grey focus:outline-none"
          type="email"
          placeholder={`${t('your-email-address')}`}
          value={email}
          onChange={handleOnChange}
        />
        {/* {success && (
          <div className="absolute left-16 top-16">
            <Image
              src={`/${locale}/green-tick.svg`}
              alt="Success"
              height={18}
              width={18}
            />
          </div>
        )} */}
        <Button
          className="rounded"
          variant="primary"
          onClick={handleSubscription}
          loading={loading}
          disabled={loading}
        >
          <span>
            <FancyArrowRight />
          </span>
        </Button>
      </div>
      {message && (
        <div
          className={cn('body mt-4 ', {
            'text-successGreen': success,
            'text-errorRed': error,
          })}
        >
          {message}
        </div>
      )}
    </Fragment>
  )
}

export default SubscribeForm
